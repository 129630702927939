// src/components/NavBar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../css/NavBar.css';
import logo from '../Assets/Logo512.png'

export const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="nav-bar full-width">
      <div className="container">
        <div className="logo">
        <Link to="/">
          {/* <h1>FlyAI</h1> */}
          <img src={logo} alt="FlyAI Logo" className="logo-img" />
        </Link>
        </div>
        <div className={`nav-links ${isOpen ? 'open' : ''}`}>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/how-it-works">How Does It Work</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/faq">FAQ</Link></li>
            {/* <li><Link to="/member-area">Member Area</Link></li> */}
          </ul>
        </div>
        <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="line top"></div>
          <div className="line middle"></div>
          <div className="line bottom"></div>
        </div>
      </div>
    </nav>
  );
};
