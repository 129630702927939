// src/components/BlogPost.js
import React, { useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import '../css/BlogPost.css';

export const BlogPost = () => {
  const { blogpostname } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const post = location.state?.post;

  const [copied, setCopied] = useState(false);

  const copyLinkToClipboard = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }, (err) => {
      console.error('Failed to copy: ', err);
    });
  };

  if (!post) {
    console.log(`Blog post not found for: ${blogpostname}`);
    return <div className="blog-post-container"><h2>Blog post not found</h2></div>;
  }

  console.log(`Rendering post for: ${blogpostname}`);
  return (
    <div className="blog-post-container">
      <h2>{post.title}</h2>
      <p className="date">{post.date}</p>
      <div dangerouslySetInnerHTML={{ __html: post.content }} />
      <div className='bottom-btns'>
          {copied && <span className="copied-message">Link copied!</span>}
        <div className="share-buttons">
          <button onClick={copyLinkToClipboard} className="share-button">Share</button>
        </div>
        <button onClick={() => navigate('/blog')} className="back-button">Back to Blog</button>
      </div>
    </div>
  );
};
