// src/components/CloudAnimation.js
import React from 'react';
import Lottie from 'react-lottie';
import CloudsAnimation from '../Assets/cloudsAnimation.json';

export const CloudAnimation = ({ style, className }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: CloudsAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={className} style={style}>
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
};
