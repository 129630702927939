import React, { useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import UfoAnimation from '../Assets/UfoAnimation.json';

export const MovingUfo = ({ style, className }) => {
    const ufoRef = useRef(null);

    useEffect(() => {
      const timer = setTimeout(() => {
        if (ufoRef.current) {
          ufoRef.current.classList.add('start-animation');
        }
      }, 50000); // 50 seconds delay
  
      const removeTimer = setTimeout(() => {
        if (ufoRef.current) {
          ufoRef.current.remove();
        }
      }, 65000); // 65 seconds total (50s delay + 15s animation)
  
      return () => {
        clearTimeout(timer);
        clearTimeout(removeTimer);
      };
    }, []);
  
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: UfoAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
  
    return (
      <div ref={ufoRef} className={className} style={style}>
        <Lottie options={defaultOptions} height={150} width={300} />
      </div>
    );
  };
