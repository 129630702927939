// src/components/Loading.js
import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../Assets/AirPlaneLoading.json';
import '../css/AirPlaneAnimation.css';

export const AirPlaneAnimation = ({ isLoading }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  if (!isLoading) {
    return null;
  }

  return (
    <div className="loading-container">
        <h1>Searching for your next vacation</h1>
      <Lottie options={defaultOptions} height={400} width={400} />
    </div>
  );
};
