import React, { useState } from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import {
  FaFacebookSquare,
  FaTwitter,
  FaLinkedin,
  FaWhatsappSquare,
  FaLink,
} from 'react-icons/fa';
import '../css/ShareLinksBtns.css';

export const ShareLinksBtns = ({ url }) => {
  const shareUrl = url || window.location.href;
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Hide the message after 2 seconds
    });
  };

  const sharedText = "Check that out! You can now search for a vacation with AI using FlyAI. Here is the link: ";

  return (
    <div className="share-buttons-social-media">
      <FacebookShareButton url={shareUrl} quote={sharedText} className="general-share-button">
        <FaFacebookSquare className="icon-button" size={32} />
      </FacebookShareButton>

      <WhatsappShareButton url={shareUrl} title={sharedText} separator=":: " className="general-share-button">
        <FaWhatsappSquare className="icon-button" size={32} />
      </WhatsappShareButton>

      {/* <LinkedinShareButton url={shareUrl} title={sharedText} summary={sharedText} source={shareUrl} className="general-share-button">
        <FaLinkedin className="icon-button" size={32} />
      </LinkedinShareButton> */}

      <TwitterShareButton url={shareUrl} title={sharedText} className="general-share-button">
        <FaTwitter className="icon-button" size={32} />
      </TwitterShareButton>

      <button onClick={handleCopyLink} className="general-share-button">
        <FaLink className="icon-button" size={32} />
      </button>

      {copied && <span className="copy-feedback">Link copied!</span>}
    </div>
  );
};

export default ShareLinksBtns;
