// src/components/Blog.js
import React from 'react';
import { Link } from 'react-router-dom';
import { blogPosts } from '../config/BlogsContent';
import '../css/Blog.css';


export const Blog = () => {
  return (
    <div className="blog-container">
      <h2>FlyAI Blog</h2>
      {blogPosts.map((post, index) => (
        <Link 
          to={`/blog/${post.name}`} 
          key={index} 
          className="blog-post-link"
          state={{ post }}
        >
          <div className="blog-post">
            <h3>{post.title}</h3>
            <p className="date">{post.date}</p>
            <p>{post.summary}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};
