import React, { useEffect, useState } from 'react';
import { SearchResultFlight } from './SearchResultFlight';
import { SearchResultHotel } from './SearchResultHotel';
import { SearchResultBundle } from './SearchResultBundle';
import '../css/SearchResults.css';

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(2);
  return `${day}/${month}/${year}`;
};

export const SearchResults = ({ type, data }) => {
  const [results, setResults] = useState({ flights: [], hotels: [], bundles: [] });
  const [location, setLocation] = useState('');

  useEffect(() => {
    if (data) {
      setResults(data);
      if (data.hotels && data.hotels.length > 0) {
        setLocation(data.hotels[0].location);
      }
    } else {
      setResults({ flights: [], hotels: [], bundles: [] });
    }
  }, [data]);

  if (!results) {
    return <div>No results found</div>;
  }

  const generateHeading = () => {
    const checkInDate = formatDate(data.check_in_date_str);
    const checkOutDate = formatDate(data.check_out_date_str);
    switch (type) {
      case 'flights':
        return `Flights from your selected origin to ${location}:`;
      case 'hotels':
        return `Hotels in ${location} from ${checkInDate} to ${checkOutDate}:`;
      case 'bundles':
        return `Travel bundles for ${location}:`;
      default:
        return 'Search Results:';
    }
  };

  return (
    <div className='search-results'>
      {type === 'flights' && results.flights && results.flights.length > 0 && (
        <>
          <h2>{generateHeading()}</h2>
          {results.flights.map(flight => (
            <SearchResultFlight key={flight.id} flight={flight} />
          ))}
        </>
      )}
      {type === 'hotels' && results.hotels && results.hotels.length > 0 && (
        <>
          <h2>{generateHeading()}</h2>
          {results.hotels.map(hotel => (
            <SearchResultHotel 
              key={hotel.id} 
              hotel={hotel} 
              checkInDate={formatDate(data.check_in_date_str)} 
              checkOutDate={formatDate(data.check_out_date_str)} 
            />
          ))}
        </>
      )}
      {type === 'bundles' && results.bundles && results.bundles.length > 0 && (
        <>
          <h2>{generateHeading()}</h2>
          {results.bundles.map(bundle => (
            <SearchResultBundle key={bundle.id} bundle={bundle} />
          ))}
        </>
      )}
    </div>
  );
};
