import React, { useEffect, useRef } from 'react';
import Lottie from 'react-lottie';
import AirPlaneFlying2 from '../Assets/AirPlaneFlying2.json';

export const MovingAirplane = ({ style, className }) => {
  const airplaneRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (airplaneRef.current) {
        airplaneRef.current.classList.add('start-animation');
      }
    }, 50000); // 50 seconds delay

    const removeTimer = setTimeout(() => {
      if (airplaneRef.current) {
        airplaneRef.current.remove();
      }
    }, 65000); // 60 seconds total (50s delay + 10s animation)

    return () => {
      clearTimeout(timer);
      clearTimeout(removeTimer);
    };
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: AirPlaneFlying2,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div ref={airplaneRef} className={className} style={style}>
      <Lottie options={defaultOptions} height={200} width={300} />
    </div>
  );
};
