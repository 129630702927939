// src/components/SearchResultFlight.js
import React from 'react';
import { MdFlightTakeoff } from "react-icons/md"; // Import departure icon
import { RiFlightLandLine } from "react-icons/ri"; // Import arrival icon
import '../css/SearchResultFlight.css';

export const SearchResultFlight = ({ flight }) => {
  return (
    <div className="flight-result">
      <p>{flight.description}</p>
      <p>Airline: {flight.airline}</p>
      <p><MdFlightTakeoff /> Departure: {flight.departure}</p>
      <p><RiFlightLandLine /> Arrival: {flight.arrival}</p>
      <p>Time: {flight.time}</p>
      <p>Price: {flight.price}</p>
      <a href={flight.link} target="_blank" rel="noopener noreferrer">Book this flight</a>
    </div>
  );
};
