// src/components/Header.js
import React from 'react';
import '../css/Header.css';

export const Header = () => {
  return (
    <header className="header">
      {/* <h1>Fly AI</h1> */}
      <h1>Find your next vacation,
        <br />
         with the magic of AI</h1>
      <h2>type your search to get started</h2>
    </header>
  );
}
