import React, { useEffect, useState } from 'react';
import '../css/MovingClouds.css';
import { CloudAnimation } from './CloudAnimation';
import { MovingAirplane } from './MovingAirplane';
import { MovingUfo }  from './UfoAnimation';

export const MovingClouds = () => {
  const [showUfo, setShowUfo] = useState(false);

  useEffect(() => {
    const randomChance = Math.random();
    if (randomChance < 0.1) {
      setShowUfo(true);
    }
  }, []);

  return (
    <>
      <CloudAnimation className="cloud-animation left-to-right" style={{ top: '10%', '--start-left': '5%', '--duration': '70s' }} />
      <CloudAnimation className="cloud-animation right-to-left" style={{ top: '23%', '--start-right': '75%', '--duration': '80s' }} />
      <CloudAnimation className="cloud-animation left-to-right" style={{ top: '40%', '--start-left': '30%', '--duration': '90s' }} />
      {showUfo ? (
        <MovingUfo className="ufo-animation" style={{ top: '42%' }} />
      ) : (
        <MovingAirplane className="airplane-animation" style={{ top: '42%' }} />
      )}
    </>
  );
};
