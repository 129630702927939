// src/components/FAQ.js
import React, { useState } from 'react';
import '../css/FAQ.css';

export const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is FlyAI?",
      answer: "FlyAI is a platform that uses artificial intelligence to help you find the best flights, hotels, and travel bundles tailored to your needs."
    },
    {
      question: "How does FlyAI find the best travel options?",
      answer: "Our AI algorithms analyze a large amount of data from various sources to match your preferences with the best available travel options."
    },
    {
      question: "Is FlyAI free to use?",
      answer: "Yes, FlyAI is free to use. We aim to provide you with the best travel options without any additional costs."
    },
    {
      question: "Can I trust the travel recommendations provided by FlyAI?",
      answer: "Absolutely. Our AI is designed to provide the most accurate and reliable travel recommendations based on your preferences and current data."
    }
  ];

  return (
    <div className="faq-container">
      <h2>Frequently Asked Questions</h2>
      {faqs.map((faq, index) => (
        <div key={index} className={`faq-item ${openIndex === index ? 'open' : ''}`}>
          <h3 onClick={() => toggleFAQ(index)}>{faq.question}</h3>
          <p>{faq.answer}</p>
        </div>
      ))}
    </div>
  );
};
