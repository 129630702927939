import React, { useState, useEffect } from 'react';
import '../css/SearchBar.css';
import { PiAirplaneTakeoffLight } from "react-icons/pi"; 
import { MdOutlineHotel } from "react-icons/md"; 
import { MdOutlineBeachAccess } from "react-icons/md"; 
import { GoPaperAirplane } from "react-icons/go"; 

export const SearchBar = ({ onSearch, startLoading }) => {
  const [activeButton, setActiveButton] = useState('hotels');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [searchText, setSearchText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [comingSoon, setComingSoon] = useState(false); // State to control "Coming Soon" message
  const [noResultsMessage, setNoResultsMessage] = useState(''); // State to display "No results found" message

  const handleButtonClick = (button) => {
    setActiveButton(button);
    // Show "Coming Soon" message for flights and bundles
    if (button === 'flights' || button === 'bundles') {
      setComingSoon(true);
    } else {
      setComingSoon(false);
    }
  };

  const handleSearchClick = () => {
    if (searchText.length >= 15) {
      setNoResultsMessage(''); // Reset no results message
      setErrorMessage(''); // Reset error message
      startLoading();
      if (activeButton === 'hotels') {
        fetch(`https://flyaiback.azurewebsites.net/api/http_trigger?code=${process.env.REACT_APP_AGODA_KEY}`, {
          headers: {
            "accept": "/",
            "content-type": "application/json",
            "User-Agent": "MyUserAgent",
            "Access-Control-Allow-Origin": "*"
          },
          body: JSON.stringify({"user_request": searchText}),
          method: "POST"
        })
        .then(response => response.json())
        .then(data => {
          console.log('Data received:', data); // Debug log

          // Check if the hotels array is empty
          if (data.hotels && data.hotels.length === 0) {
            setNoResultsMessage('No hotels found for your search, Please try again with different keywords');
          } else {
            onSearch(activeButton, data); // Pass data to onSearch
          }

          startLoading(false); // Stop loading animation after fetch is complete
        })
        .catch(error => {
          console.error('Error during fetch:', error); // Debug log
          setErrorMessage('Sorry, an error occurred');
          startLoading(false); // Stop loading animation
        });
      } else {
        onSearch(activeButton, null);
        setErrorMessage('');
      }
    } else {
      setErrorMessage('Please enter a valid vacation search');
    }
  };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  // Handle Enter key press to trigger search
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !comingSoon) {
      event.preventDefault(); // Prevent default Enter key behavior (new line)
      handleSearchClick();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="search-bar">
      <div className="search-buttons">
        <button
          className={activeButton === 'hotels' ? 'active' : ''}
          onClick={() => handleButtonClick('hotels')}
        >
          <MdOutlineHotel style={{ marginRight: '8px' }} />
          {isMobile ? 'Hotels' : 'Search hotels'}
        </button>

        <button
          className={activeButton === 'flights' ? 'active' : ''}
          onClick={() => handleButtonClick('flights')}
        >
          <PiAirplaneTakeoffLight style={{ marginRight: '8px' }} />
          {isMobile ? 'Flights' : 'Search flights'}
        </button>
        
        <button
          className={activeButton === 'bundles' ? 'active' : ''}
          onClick={() => handleButtonClick('bundles')}
        >
          <MdOutlineBeachAccess style={{ marginRight: '8px' }} />
          {isMobile ? 'Bundles' : 'Search bundles'}
        </button>
      </div>

      {comingSoon ? (
        <div className="coming-soon-message">Coming Soon :)</div>
      ) : (
        <>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {noResultsMessage && <div className="no-results-message">{noResultsMessage}</div>}
          <div className="search-input">
            <div className='text-counter'>
              <textarea 
                placeholder="Type your vacation search here...
                Try describing the ideal time, budget, and the place you want to visit" 
                value={searchText} 
                onChange={handleInputChange}
                onKeyPress={handleKeyPress} // Add key press handler
              ></textarea>
              <span className="character-count">{searchText.length} / 15 min</span>
            </div>
            <button className="search-icon-button" onClick={handleSearchClick}>
              <GoPaperAirplane />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
