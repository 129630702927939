// src/components/SearchResultBundle.js
import React from 'react';
import { MdFlightTakeoff } from "react-icons/md"; // Import departure icon
import { RiFlightLandLine } from "react-icons/ri"; // Import arrival icon
import { FaStar } from "react-icons/fa6"; // Import star icon
import '../css/SearchResultBundle.css';

export const SearchResultBundle = ({ bundle }) => {
  return (
    <div className="bundle-result">
      <p>{bundle.flight.description}</p>
      <p>Airline: {bundle.flight.airline}</p>
      <p><MdFlightTakeoff /> Departure: {bundle.flight.departure}</p>
      <p><RiFlightLandLine /> Arrival: {bundle.flight.arrival}</p>
      <p>Time: {bundle.flight.time}</p>
      <p>Flight Price: {bundle.flight.price}</p>
      <a href={bundle.flight.link} target="_blank" rel="noopener noreferrer">Book this flight</a>
      <p>{bundle.hotel.description}</p>
      <p>Hotel Name: {bundle.hotel.name}</p>
      <p>Location: {bundle.hotel.location}</p>
      <p>Hotel Price: {bundle.hotel.price}</p>
      <p>Rating: <FaStar /> {bundle.hotel.rating}</p>
      <a href={bundle.hotel.link} target="_blank" rel="noopener noreferrer">Book this hotel</a>
    </div>
  );
};
