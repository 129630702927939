export const blogPosts = [
    {
      name: "welcome-to-flyai-blog",
      title: "Welcome to FlyAI Blog",
      date: "June 24, 2024",
      summary: "We're excited to launch our blog where we'll share the latest news, travel tips, and updates about FlyAI. Stay tuned for more!",
      content: `
        <p>Welcome to the FlyAI Blog!</p>
        <p>We are thrilled to announce the launch of our new blog, a space where we will be sharing the latest news, travel tips, and updates about FlyAI. Our goal is to create a community where travel enthusiasts can find valuable information, inspiration, and the latest trends in the travel industry.</p>
        
        <h3>What to Expect</h3>
        <p>On our blog, you can look forward to:</p>
        <ul>
          <li><strong>Latest News:</strong> Stay updated with the newest features and services FlyAI offers to enhance your travel experience.</li>
          <li><strong>Travel Tips:</strong> Get insider tips to make your travel smoother, more enjoyable, and cost-effective.</li>
          <li><strong>Destination Guides:</strong> Discover detailed guides about exciting travel destinations around the world.</li>
          <li><strong>Customer Stories:</strong> Read about the amazing experiences of our customers and how FlyAI has helped them on their journeys.</li>
          <li><strong>Industry Insights:</strong> Learn about the latest trends and innovations in the travel industry.</li>
        </ul>
        
        <h3>Join the FlyAI Community</h3>
        <p>We invite you to join us on this exciting journey. Follow our blog for regular updates, share your thoughts in the comments, and connect with us on social media. Your feedback is invaluable as we strive to make FlyAI the best travel companion for you.</p>
        <p>Stay tuned for more exciting posts coming your way soon!</p>
        <p>Happy Travels,<br/>The FlyAI Team</p>
      `
    },
    {
      name: "top-5-travel-destinations-for-2024",
      title: "Top 5 Travel Destinations for 2024",
      date: "June 18, 2024",
      summary: "Discover the top travel destinations for 2024 and plan your next adventure with FlyAI. From exotic beaches to bustling cities, we've got you covered.",
      content: `
        <p>Top 5 Travel Destinations for 2024</p>
        <p>Are you ready to plan your next adventure? We've curated a list of the top travel destinations for 2024 to help inspire your travels. Whether you're looking for exotic beaches, bustling cities, or cultural experiences, FlyAI has got you covered.</p>
        
        <h3>1. Kyoto, Japan</h3>
        <p>Experience the serene beauty of Kyoto, a city that seamlessly blends ancient traditions with modern charm. Kyoto is home to over a thousand temples and shrines, including the iconic Kinkaku-ji (Golden Pavilion) and Fushimi Inari Shrine with its thousands of red torii gates. The city is particularly famous for its beautiful gardens and traditional tea houses, where you can partake in a traditional tea ceremony. Don't miss the historic Gion district, where you might spot a geisha walking along the cobbled streets. Springtime in Kyoto is a magical experience with cherry blossoms in full bloom, while autumn offers a stunning display of red maple leaves.</p>
        
        <h3>2. Lisbon, Portugal</h3>
        <p>Lisbon, the capital of Portugal, is a city that effortlessly combines rich history with a vibrant contemporary culture. Wander through the narrow, winding streets of Alfama, the oldest district in Lisbon, and visit the impressive São Jorge Castle for panoramic views of the city. The Belém area is home to the famous Belém Tower and Jerónimos Monastery, both UNESCO World Heritage Sites. Lisbon's culinary scene is a delight, with traditional dishes like bacalhau (salted cod) and the sweet pastéis de nata (custard tarts). The city's nightlife is equally enticing, with a variety of bars and clubs offering everything from traditional Fado music to modern beats.</p>
        
        <h3>3. Santorini, Greece</h3>
        <p>Santorini is a dream destination, known for its breathtaking sunsets, white-washed buildings, and crystal-clear waters. The island's unique volcanic landscape adds to its charm, with black sand beaches and stunning caldera views. Explore the picturesque village of Oia, famous for its stunning sunsets and charming streets lined with boutiques and cafes. The capital, Fira, offers a lively atmosphere with a mix of traditional and modern attractions. Don't miss a visit to the ancient ruins of Akrotiri, a Minoan Bronze Age settlement preserved in volcanic ash. For wine enthusiasts, Santorini's vineyards produce some of the best wines in Greece, thanks to the island's volcanic soil.</p>
        
        <h3>4. Buenos Aires, Argentina</h3>
        <p>Buenos Aires is a city that pulsates with energy and passion. Known as the "Paris of South America," it boasts a rich cultural heritage and vibrant arts scene. Stroll through the colorful streets of La Boca, visit the historic Recoleta Cemetery, and take in a live tango performance in San Telmo. The city's food scene is a gastronomic delight, with mouthwatering steaks, empanadas, and fine wines. Palermo, one of the trendiest neighborhoods, is perfect for exploring chic boutiques, cozy cafes, and lively bars. The city's mix of European architecture and Latin flair creates a unique and captivating atmosphere.</p>
        
        <h3>5. New York City, USA</h3>
        <p>New York City, the city that never sleeps, offers an endless array of attractions and experiences. From the iconic Statue of Liberty and Empire State Building to the sprawling Central Park, there's something for everyone. Explore world-class museums like the Metropolitan Museum of Art and the Museum of Modern Art, or catch a Broadway show in the Theater District. The city's diverse neighborhoods each have their own unique charm, from the hipster vibes of Brooklyn to the historic streets of Harlem. Foodies will love the city's culinary diversity, with an array of restaurants offering cuisine from around the world. Whether you're shopping on Fifth Avenue, exploring the vibrant arts scene, or simply taking in the hustle and bustle, New York City promises an unforgettable experience.</p>
        
        <p>Plan your next adventure with FlyAI and explore these incredible destinations. Whether you're a solo traveler, a couple, or a family, there's something for everyone in our top picks for 2024.</p>
        <p>Happy Travels,<br/>The FlyAI Team</p>
      `
    },    
    {
      name: "how-ai-is-changing-travel-planning",
      title: "How AI is Changing Travel Planning",
      date: "June 10, 2024",
      summary: "Learn how artificial intelligence is revolutionizing the travel industry and making it easier than ever to find the best travel deals and experiences.",
      content: `
        <p>How AI is Changing Travel Planning</p>
        <p>In today's fast-paced world, planning a trip can be both exciting and overwhelming. However, with the advent of artificial intelligence (AI), the travel industry is undergoing a transformation that is making it easier than ever to plan and enjoy your travels.</p>
        
        <h3>Personalized Travel Recommendations</h3>
        <p>AI-powered algorithms analyze your preferences, travel history, and interests to provide personalized travel recommendations. Whether you're looking for a quiet beach getaway or an adventurous mountain hike, AI can help you find the perfect destination.</p>
        
        <h3>Efficient Booking Processes</h3>
        <p>AI streamlines the booking process by comparing prices, checking availability, and even booking flights and accommodations on your behalf. This not only saves you time but also ensures you get the best deals available.</p>
        
        <h3>Enhanced Customer Service</h3>
        <p>Virtual travel assistants and chatbots are now common in the travel industry, providing 24/7 customer service. They can answer your queries, provide real-time updates, and even assist with itinerary changes, making your travel experience smoother and more convenient.</p>
        
        <h3>Predictive Analytics</h3>
        <p>AI uses predictive analytics to forecast travel trends and demands. This helps travelers plan their trips during the best times to avoid crowds and high prices, ensuring a more enjoyable and cost-effective experience.</p>
        
        <h3>Improved Travel Safety</h3>
        <p>AI technologies are enhancing travel safety by monitoring global events and providing real-time alerts about potential risks. This ensures travelers are well-informed and can make safer travel decisions.</p>
        
        <h3>Smart Itinerary Planning</h3>
        <p>AI can create smart itineraries tailored to your interests and schedule. By analyzing data on attractions, opening hours, and distances, AI ensures you make the most of your trip without the hassle of manual planning.</p>
        
        <h3>Future Innovations</h3>
        <p>The future of AI in travel planning is bright, with continuous advancements expected. From virtual reality tours to AI-powered travel companions, the possibilities are endless, promising even more innovative and convenient travel experiences.</p>
        
        <p>Embrace the power of AI with FlyAI and enjoy a seamless, personalized, and efficient travel planning experience. Stay tuned to our blog for more insights into how technology is shaping the future of travel.</p>
        <p>Safe Travels,<br/>The FlyAI Team</p>
      `
    }
  ];