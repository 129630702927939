// src/components/About.js
import React from 'react';
import '../css/About.css';

export const About = () => {
  return (
    <div className="about-container">
      <h2>About FlyAI</h2>
        <div className='about-content'>
            <p>
                FlyAI is an innovative platform that leverages artificial intelligence to provide tailored travel solutions. Our mission is to simplify the travel planning process by finding the best flights, hotels, and travel bundles that meet your unique needs.
            </p>
            <p>
                With FlyAI, you can rest assured that you are getting the most personalized and efficient travel options available. Our advanced algorithms consider numerous factors to deliver results that save you time and money.
            </p>
      </div>
    </div>
  );
};
