// src/App.js
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './components/Home';
import { NavBar } from './components/NavBar';
import { Footer } from './components/Footer';
import { About } from './components/About';
import { HowDoesItWork } from './components/HowDoesItWork';
import { FAQ } from './components/FAQ';
import { Blog } from './components/Blog';
import { BlogPost } from './components/BlogPost';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/how-it-works" element={<HowDoesItWork />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:blogpostname" element={<BlogPost />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
