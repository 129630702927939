// src/components/Footer.js
import React from 'react';
import '../css/Footer.css';

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer full-width">
      <div className="container">
        <ul>
          <li><a href="/blog">Blog</a></li>
          <li><a href="mailto:ofri.yehuda27@gmail.com">Contact</a></li>
          {/* <li><a href="#privacy">Privacy</a></li> */}
        </ul>
        <p>All rights reserved FlyAI {currentYear}</p>
      </div>
    </footer>
  );
}
