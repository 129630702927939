// src/components/HowDoesItWork.js
import React from 'react';
import '../css/HowDoesItWork.css';

export const HowDoesItWork = () => {
  return (
    <div className="how-it-works-container">
      <h2>How Does FlyAI Work?</h2>
      <div className='how-it-works-content'>
        <p>
            FlyAI uses state-of-the-art artificial intelligence to analyze your travel preferences and requirements. Here's how it works:
        </p>
        <ol>
            <li><strong>Input Your Preferences:</strong> Start by entering your travel details, including your destination, travel dates, budget, and any specific preferences.</li>
            <li><strong>AI Analysis:</strong> Our AI algorithms analyze a vast amount of data from various sources to find the best matches for your criteria.</li>
            <li><strong>Get Tailored Results:</strong> Receive a list of the best flights, hotels, and travel bundles that suit your needs. You can further refine the results to match your exact preferences.</li>
        </ol>
        <p>
            Experience the future of travel planning with FlyAI!
        </p>
      </div>
    </div>
  );
};
