import React, { useState } from 'react';
import { Header } from './Header';
import { SearchBar } from './SearchBar';
import { SearchResults } from './SearchResults';
import '../css/Home.css';
import { MovingClouds } from './MovingClouds';
import { AirPlaneAnimation } from './AirPlaneAnimation';
import { ShareLinksBtns } from './ShareLinksBtns';

export const Home = () => {
  const [searchType, setSearchType] = useState(null);
  const [searchData, setSearchData] = useState(null); // State to store search data
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSearch = (type, data) => {
    setSearchType(type);
    setSearchData(data); // Set the search data
    setSearchPerformed(true); // Set searchPerformed to true when search is performed
    setIsLoading(false); // Stop loading animation
  };

  const startLoading = (isLoading = true) => {
    setIsLoading(isLoading);
  };

  return (
    <div className="home full-width">
      <div className="container">
        <Header />
        <SearchBar onSearch={handleSearch} startLoading={startLoading} />
        {searchPerformed && searchType && <SearchResults type={searchType} data={searchData} />}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <ShareLinksBtns />
      </div>
      <MovingClouds />
      <AirPlaneAnimation isLoading={isLoading} />
    </div>
  );
};
