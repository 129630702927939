import React, { useState } from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar, FaMapMarkerAlt } from "react-icons/fa"; // Import star and map icons
import '../css/SearchResultHotel.css';

const renderStars = (rating) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  const emptyStars = 5 - Math.ceil(rating);

  return (
    <div className="stars">
      {Array.from({ length: fullStars }, (_, i) => (
        <FaStar key={`full-${i}`} color='gold' />
      ))}
      {halfStar && <FaStarHalfAlt color='gold' />}
      {Array.from({ length: emptyStars }, (_, i) => (
        <FaRegStar key={`empty-${i}`} color='gold' />
      ))}
    </div>
  );
};

export const SearchResultHotel = ({ hotel, checkInDate, checkOutDate }) => {
  const [copySuccess, setCopySuccess] = useState('');

  const handleCopyLink = () => {
    navigator.clipboard.writeText(hotel.link).then(() => {
      setCopySuccess('Link copied!');
      setTimeout(() => {
        setCopySuccess('');
      }, 2000);
    }, () => {
      setCopySuccess('Failed to copy link');
      setTimeout(() => {
        setCopySuccess('');
      }, 5000);
    });
  };

  return (
    <div className="hotel-result">
      <p className="hotel-description">{hotel.description}</p>
      <div className="hotel-name-rating">
        <p className="hotel-name">{hotel.name}</p>
        {renderStars(hotel.rating)}
      </div>
      <div className="hotel-details-grid">
        <p><FaMapMarkerAlt /> <span className="hotel-detail">{hotel.location}</span></p>
        <p><span className="hotel-detail">${Math.round(hotel.price_per_night)} USD per night</span></p>
        <p>Check-in <span className="hotel-detail">{checkInDate}</span></p>
        <p>Check-out <span className="hotel-detail">{checkOutDate}</span></p>
      </div>
      <div className="hotel-actions">
        <a href={hotel.link} target="_blank" rel="noopener noreferrer" className="hotel-booking-link">Book this hotel</a>
        <button onClick={handleCopyLink} className="hotel-share-link">Share this hotel</button>
      </div>
      {copySuccess && <p className="copy-success">{copySuccess}</p>}
    </div>
  );
};
